import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
const Project = ({ image, title, description, duration, services, slug }) => {
  const styles = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
  }

  return (
    <div className="Project">
      <div className="Project__img" style={styles}></div>
      <h3 className="Project__title">{title}</h3>
      <p className="Project__desc">{description}</p>
      {duration && <p className="Project__desc">Duration: {duration}</p>}
      <ul>
        {services.map((service, i) => (
          <li key={i}>{service}</li>
        ))}
      </ul>
      <Link to={slug} className="Btn Btn--dark">
        View Project Details
      </Link>
    </div>
  )
}

Project.defaultProps = {
  image: '',
  title: 'No title available',
  description: 'No Description available',
  services: [],
  duration: '',
}

Project.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  services: PropTypes.array,
  duration: PropTypes.string,
}

export default Project
